import React from "react"
import Layout from "components/Layout"

import notfound from "images/notfound.png"

const NotFoundPage = () => (
  <Layout>
    <h1>
      <img src={notfound} alt="NOT FOUND" className="img is-mobile-full" />
    </h1>
  </Layout>
)

export default NotFoundPage
